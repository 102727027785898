/*
 Created on : 22.07.2015, 16:12:20
 Changes :
 07.10.2015 - included percentage grid and rename old one to generatePixelGrid
 28.10.2015 - introduced prefixes
 27.01.2016 - added/fixed boxed behavior
 17.02.2016 - removes pixel-based grid calculation
 15.04.2016 - replace with css-calc
 24.06.2016 - remove junk
 23.09.2016 - tuned
*/

@mixin generatePercentageGrid($intColumns: 12, $prefix: '', $intMarginLeft: 5, $intMarginRight: 5) {
    @for $i from 0 through $intColumns {
        .col-#{$prefix}#{$i} {
            width: calc(100% * #{$i} / #{$intColumns} - #{$intMarginRight + $intMarginLeft + 1}px);
            &.mod_article,
            &.no-margin {
                width: calc(100% * #{$i} / #{$intColumns});
            }
            &.no-margin {
                @if($intMarginRight == $intMarginLeft) {
                    &-left,
                    &-right {
                        width: calc(100% * #{$i} / #{$intColumns} - #{$intMarginLeft + 1}px);
                    }
                }
                @else {
                    &-left {
                        width: calc(100% * #{$i} / #{$intColumns} - #{$intMarginRight + 1}px);
                    }
                    &-right {
                        width: calc(100% * #{$i} / #{$intColumns} - #{$intMarginLeft + 1}px);
                    }
                }
            }
        }
        .offset-#{$prefix}#{$i} {
            margin-left: calc(100% * #{$i} / #{$intColumns} + #{$intMarginLeft + 1}px);
            &.mod_article {
                margin-left: calc(100% * #{$i} / #{$intColumns});
            }
        }
        .push-#{$prefix}#{$i} {
            left: calc(100% * #{$i} / #{$intColumns});
        }
        .pull-#{$prefix}#{$i} {
            right: calc(100% * #{$i} / #{$intColumns});
        }
    }
}
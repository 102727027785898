/* 
    Created on : 26.02.2015, 13:21:0
    Changes     : 
        22.07.2015, 11:24:00
        07.07.2016 - fixed gallery-listing, remove junk
*/

.invisible {
    position: absolute;
    left: -6000px;
    top: -6000px;
    display: none;
}

nav {
    ul {
        margin: 0;        
    }
    a {
        text-decoration: none;
    }
}

a {
    img {
        display: block;
    }
}

figure {
    &.float {
        &_right {
            float: right; 
        }
        &_left {
            float: left;
        }
    }
    img {
        display: block;
    }
}

.ce_gallery {
    $marginSum: $marginRight + $marginLeft;
    ul {
        margin-top: 0
    }
    li {
        float: left;
        vertical-align: middle;
        margin: {
            top: 0;
            right: $marginSum * 1px;
            bottom: $marginSum * 1px; 
        }
    }
    figure {
        margin: 0
    }
    img {
        max-width: 100%;
        height: auto;
    }
    .cols_1 {
        li {
            width: 100%;
            margin-right: 0
        }
    }
    @media(max-width: 480px) {
        .cols_2,
        .cols_3,
        .cols_4,
        .cols_5,
        .cols_6,
        .cols_7,
        .cols_8,
        .cols_9,
        .cols_10,
        .cols_11,
        .cols_12 {
            li {
                margin-right: 0
            }
        }
    }
    @media(min-width: 481px) {
        .cols_2,
        .cols_3,
        .cols_4,
        .cols_5,
        .cols_6,
        .cols_7,
        .cols_8,
        .cols_9,
        .cols_10,
        .cols_11,
        .cols_12 {
            li {
                width: calc((100% - #{$marginSum}px) / 2);
                @media(max-width: 768px) {
                    &:nth-child(2n + 1) {
                        clear: left;
                    }
                    &:nth-child(2n) {
                        margin-right: 0;
                    }
                }
            }
        }
    }
    @media(min-width: 769px) {
        @for $i from 2 to 12 {
            .cols_#{$i} {
                li {
                    $intVal: $marginSum * ($i - 1);
                    width: calc((100% - #{$intVal}px) / #{$i});
                    &:nth-child(#{$i}n + 1) {
                        clear: left;
                    }
                    &:nth-child(#{$i}n) {
                        margin-right: 0;
                    }
                }
            }        
        }
    }
}

.ce_accordion,
.ce_text, 
.ce_list {
    ul, ol {
        li {
            margin-left: 2rem;
        }        
    }
    ul {
        list-style-type: disc;
    }
    ol {
        list-style-type: decimal;
    }
}

label {
    > span.mandatory {
        display: inline
    }
}
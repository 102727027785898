/*
    Created on : 03.02.2015, 08:44:48
    Description: Some SASS mixins
*/

@mixin transform($scale) {
    -moz-transform: $scale;
    -webkit-transform: $scale;
    -o-transform: $scale;
    -ms-transform: $scale;
    transform: $scale;
}

@mixin transition($trans...) {
    -moz-transition: $trans;
    -webkit-transition: $trans;
    -o-transition: $trans;
    -ms-transition: $trans;
    transition: $trans;
}

@mixin font-size($sizeValue: $defaultFontSize, $lineHeight: $defaultLineHeight) {
    font-size: $sizeValue + px;
    font-size: rempharize($sizeValue);

    $lineMulti: ceil($sizeValue / $lineHeight) * $lineHeight;
    line-height: $lineMulti + px;
    line-height: rempharize($lineMulti);
}

@mixin font-size-withMargin($sizeValue: $defaultFontSize, $lineHeight: $defaultLineHeight) {
    @include font-size($sizeValue,$lineHeight);
    margin: {
        /*top: $lineHeight + px;*/
        /*top: rempharize($lineHeight);*/
        bottom: $lineHeight + px;
        bottom: rempharize($lineHeight);
    }
}

@function rempharize($doubleValue: $defaultFontSize) {
    @return $doubleValue / 16 * 1rem; 
}

@mixin lineHeightMargin($strPos: "bottom", $doubleValue: 1) {
    margin: {
        #{$strPos}: $doubleValue * $defaultLineHeight * 1px;   
    }
}
@mixin lineHeightPadding($strPos: "bottom", $doubleValue: 1) {
    padding: {
        #{$strPos}: $doubleValue * $defaultLineHeight * 1px;   
    }
}

@mixin color-generator($name: 'white', $value: '#fff') {
    .fg-#{$name} {
        color: $value;
    }
    .bg-#{$name} {
        background-color: $value;
    }
}

.clearfix {
    zoom:1;
    &::after,
    &::before {
        content: "";
        display: table;
    }    
    &::after {
        clear: both;
    }
}

@include color-generator('white', #fff);
@include color-generator('black', #000);
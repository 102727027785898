/*
    Created on  : 08.05.2015, 10:15:00
    Description : Some usefull classes
    Changes     :
        09.06.2015, 16:04:00
        03.07.2015, 10:27:00
        15.07.2015, 11:57:00
*/

.hidden {
    display: none
}

.block {
    display: block;
    &-center {
        @extend .block;
        margin-left: auto;
        margin-right: auto;
    }
}

.inline {
    display: inline;
    &-block {
        display: inline-block;
    }
}

.clear {
    &, &-both {
        clear: both;
    }
    &-right {
        clear: right;
    }
    &-left {
        clear: left;
    }
}

.text {
    @each $align in center, left, right {
        &-#{$align} {
            text-align: #{$align};
        }
    }
    &-justify {
        text-align: justify;
        -webkit-word-break: break-word;
        -moz-word-break: break-word;
        -ms-word-break: break-word;
        word-break: break-word;
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
    }
}

@each $transform in uppercase, lowercase, underline, line-through {
    .#{$transform} {
        text-transform: #{$transform};
    }
}

.ultra-thin {
    font-weight: 100;
}
.thin {
    font-weight: 200;
}
.light {
    font-weight: 300;
}
.normal {
    font-weight: 400;
}
.medium {
    font-weight: 500;
}
.semi-bold {
    font-weight: 600;
}
.bold {
    font-weight: 700;
}
.extra-bold {
    font-weight: 800;
}
.ultra-bold {
    font-weight: 900;
}

.italic {
    font-style: italic;
}

%responsive {
    @extend .block;
    height: auto;
    &Image {
        max-width: 100%;
        &Full {
            width: 100%;
        }
    }
}

img {
    &.center {
        @extend .block-center;
    }
    &.responsive {
        @extend %responsive;
        @extend %responsiveImage;
    }
    &.full-responsive {
        @extend %responsive;
        @extend %responsiveImageFull;
    }
    &.circle {
        border-radius: 50%;
    }
}

figure {
    &.responsive img {
        @extend img.responsive;
    }
    &.full-responsive img {
        @extend img.full-responsive;
    }
}

hr {
    @extend .clear-both;
}

strong.no-strong {
    @extend .no-strong;
}

.no {
    &-transform {
        text-transform: none;
    }
    &-strong {
        font-weight: inherit;
    }
    &-decoration {
        text-decoration: none
    }
    &-outline {
        outline: none;
    }
    &-border {
        border-width: 0;
        @each $direction in top, right, bottom, left {
            &-#{$direction} {
                border-#{$direction}-width: 0;
            }
        }
    }
    @each $style in padding, margin {
        &-#{$style} {
            #{$style}: 0;
            @each $direction in top, right, bottom, left {
                &-#{$direction} {
                    #{$style}-#{$direction}: 0;
                }
            }
        }
    }
}

/* fancy frame animation for images */
.frame-animation {
    $intWidth: 400;
    $intHeight: 400;
    $intOffset: 10;
    $calcedWidth: $intWidth - $intOffset * 2;
    $calcedHeight: $intHeight - $intOffset * 2;
    $intFancyLength: 30;
    width: $calcedWidth * 1px;
    height: $calcedHeight * 1px;
    position: relative;
    background: rgba(255,255,255,1);
    display: inline-block;
    margin: 0;
    cursor: pointer;
    color: #2c3e50;
    box-shadow: inset 0 0 0 3px #2c3e50;
    -webkit-transition: background 0.4s 0.5s;
    transition: background 0.4s 0.5s;

    svg {
        position: absolute;
        top: $intOffset * 1px;
        left: $intOffset * 1px;
        right: 0;
        bottom: 0;

        line {
            stroke-width: 5;
            stroke: #fff;
            fill: none;
            -webkit-transition: all .5s ease-in-out;
            transition: all .5s ease-in-out;

            &.top {
                stroke-dasharray: $calcedWidth + $intFancyLength $calcedWidth - 2 * $intFancyLength;
            }

            &.bottom {
                stroke-dasharray: $calcedWidth + $intFancyLength $calcedWidth - 2 * $intFancyLength;
            }

            &.left {
                stroke-dasharray: $calcedHeight + $intFancyLength $calcedHeight - 2 * $intFancyLength;
            }

            &.right {
                stroke-dasharray: $calcedHeight + $intFancyLength $calcedHeight - 2 * $intFancyLength;
            }

            &.top {
                transform: translateX(-$calcedWidth * 1px);
            }

           &.bottom {
                transform: translateX($calcedWidth * 1px);
            }

            &.left {
                transform: translateY($calcedHeight * 1px);
            }

            &.right {
                transform: translateY(-$calcedHeight * 1px);
            }
        }
    }

    &:hover {
        background: rgba(255,255,255,0);
        -webkit-transition-delay: 0s;
        transition-delay: 0s;

        svg line {
            -webkit-transition-delay: 0s;
            transition-delay: 0s;

            &.top {
                transform: translateX(0);
            }

           &.bottom {
                transform: translateX(0);
            }

            &.left {
                transform: translateY(0);
            }

            &.right {
                transform: translateY(0);
            }

           // &.top {
           //      transform: translateX(-$calcedWidth * 1px);
           //  }

           // &.bottom {
           //      transform: translateX($calcedWidth * 1px);
           //  }

           //  &.left {
           //      transform: translateY($calcedHeight * 1px);
           //  }

           //  &.right {
           //      transform: translateY(-$calcedHeight * 1px);
           //  }
        }
    }
}
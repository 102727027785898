/* 
    Created on : 27.07.2015, 16:33:17
    Description: CSS-Anweisungen für vordefiniertes Browserverhalten
    History 
        2015-12-07: Added contao-like label handling
        2016-01-18: Fixed label handling
        2017-07-07: Fixed label handling
*/


.need-svg,
.need-js {
    display: none;
}

html {
    &.js {
        .need-js {
            display: block;
        }
    }
    &.svg {
        form label > span,
        .svg-fallback {
            display: none
        }
        .need-svg {
            display: block;
        }
    }
}


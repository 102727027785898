/*
    Created on : 12.05.2015, 09:34:00
*/

// Reset-Stylesheet
@import "_reset-uncompressed";

// funny functions für fontsize, transform & transitions
@import "_functions";

// custom grid
@import "_grid";
/*@import "_gridViewLayout";*/

/* OPTIONAL: slick-slider */
@import "../../node_modules/slick-carousel/slick/slick";

/* OPTIONAL: magnific-popup*/
@import "../../node_modules/magnific-popup/dist/magnific-popup.css";

// some special rules to apply some default styles on a contao 3.x Markup (float-classes etc.)
@import "_contao_tricks";

// Standard Zeilenhöhe in Pixel zur Berechung des Textrythmus
$defaultLineHeight: 24;
// Standard Textgröße in Pixel
$defaultFontSize: 14;

$colorDefaultBg: #f5f5f5;
$colorDefaultText: #3a3a3a;

$colorPrimary: #248d94;
$colorSecondary: #d2ac10;
$colorHighlight: #878787;

/*
    Created on : xx.xx.xxxx, xx:xx:00
*/

/* Background pattern from Subtle Patterns */
body {
    font: 100%/1em sans-serif;
    font-family: 'Roboto', sans-serif;
    color: $colorDefaultText;
    background: $colorDefaultBg url(../images/KJP-BG.jpg);
}

#wrapper {
    position: relative;
}

.mod_article, .padding {
    @extend .clearfix;
    padding-top: 70px;
    padding-bottom: 70px;

    &.accord {
        padding-bottom: 50px;
    }
}

h1, .h1,
h2, .h2,
h3, .h3 {
    font-family: 'Pangolin', cursive;
}

h1, .h1 {
    @include font-size-withMargin($defaultFontSize * 2);
}

h2, .h2 {
    @include font-size-withMargin($defaultFontSize * 1.6);
}

h3, .h3 {
    @include font-size-withMargin($defaultFontSize * 1.3);
    line-height: 1.6rem;
    margin-bottom: 1rem;
}

h4, .h4 {
    @include font-size-withMargin($defaultFontSize * 1.4);
}

h5, .h5 {
    @include font-size-withMargin($defaultFontSize * 1.2);
}

p,ul,ol,table,dl,.ce_hyperlink,div.submit,h6,.h6 {
    @include font-size-withMargin();
}

figcaption,label {
    @include font-size();
}

form {
    label {
        > span.error {
            display: block !important;
        }
    }
}

input[type=text],
input[type=email],
input[type=password],
input[type=number],
textarea,
input[type=button],
input[type=submit],
button {
    @extend .no-border;
    @extend .no-outline;
    font-size: $defaultFontSize * 1px;
    font-size: rempharize($defaultFontSize);
}

input[type=text],
input[type=email],
input[type=password],
input[type=number],
textarea {
    @extend .block;
    @include lineHeightMargin('bottom', 1);
    box-sizing: border-box;
    padding: 2px 4px;
    width: 100%;
}

.btn,
input[type=button],
input[type=submit],
button {
    cursor: pointer;
    padding: 8px 32px;
    &:hover {}
}

.btn {
    @extend .inline-block;
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: #232424;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #232424;
    opacity: 1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #232424;
    opacity: 1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #232424;
}

ul.styled {
    list-style-type: none;

    li {
        position: relative;
        padding-left: 20px;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 6px;
            width: 10px;
            height: 10px;
            background: $colorPrimary;
        }
    }
}

figure {
    @include lineHeightMargin('bottom', 1);
    &.float_right {
        margin-left: $computedMarginLeft;
    }
    &.float_left {
        margin-right: $computedMarginRight;
    }

    &.round {
        img {
            border-radius: 20px;
        }
    }
}

img.centered {
    margin: 0 auto;
}

a {
    font-family: 'Pangolin', cursive;
    text-decoration: none;
    font-size: 1rem;
    &:hover {
        color: $colorPrimary;
    }

    &.button {
        display: inline-block;
        padding: 10px 40px;
        margin-right: 20px;
        border-radius: 30px;
        color: #fff;
        font-family: 'Roboto', sans-serif;
        font-size: 0.875rem;

        &.download {
            position: relative;
            // font-size: 0.8rem;
            margin-bottom: 10px;
            padding: 10px 45px 10px 20px;

            &:after {
                content: '';
                position: absolute;
                right: 12px;
                top: 10px;
                width: 20px;
                height: 20px;
                background: url(../images/icons/download.svg) no-repeat;
                background-size: 20px;
            }
        }
    }
}

.quote {
    font-family: 'Pangolin', cursive;
    font-size: 1.4rem;
    color: $colorPrimary;
}

.inside {
    @extend .clearfix;
}

#cookie-hint {
    @extend .block;
    @extend .text-center;
    background-color: $colorSecondary;
    width: 100%;
    overflow: hidden;
    z-index: 1005;
    position: fixed;
    width: 100%;
    bottom: 0;
    p {
        margin: $defaultLineHeight / 2 * 1px 0;
    }
    a {
        @extend .inline-block;
        @extend .no-decoration;
        background-color: $colorPrimary;
        padding: 6px 16px;
        font-size: 13px;
        color: $colorSecondary;
    }
    span {
        @extend .block;
        margin-bottom: $defaultLineHeight / 2 * 1px
    }
    @media(min-width: 1280px) {
        span {
            display: inline-block;
            margin-right: 12px
        }
    }
}

#cookie-hint ~ #wrapper footer {
    padding-bottom: 200px;
}

#toplink {
    @extend .block;
    $intWidth: 40px;
    $intOffste: 15px;
    right: $intOffste;
    bottom: $intOffste;
    width: $intWidth;
    height: $intWidth;
    position: fixed;
    transition: opacity 420ms;
    font-size: 0;
    background-color: $colorSecondary;
    opacity: 1;
    z-index: 1001;
    &::before {
        content: "";
        position: absolute;
        top: ($intWidth - 3 * 12px) / 2;
        left: ($intWidth - 2 * 12px) / 2;;
        display: block;
        border: 12px solid $colorPrimary;
        border: {
            left-color:transparent;
            right-color:transparent;
            top-color:transparent;
        }
    }
}

header {
    position: fixed;
    width: 100%;
    z-index: 1001;

    nav#root {
        ul {
            position: relative;
            float: right;
            padding: 20px 10px;

            &:before {
                content: '';
                position: absolute;
                left: -40px;
                top: -30px;
                width: calc(100% + 80px);
                height: 110px;
                background: #248d94;
                border-radius: 20px;
                z-index: -1;
                transform: rotate(4deg);
                box-shadow: 0px 0px 48px 0px rgba(0,0,0,0.3)
            }

            li {
                display: inline-block;

                a,span {
                    color: #fff;
                    padding: 20px 10px;
                    font-size: 1.2rem;
                }

                &:hover, &.active, &.trail {
                    a,span {
                        color: $colorSecondary;
                    }
                }
            }
        }
    }
}

.frontpage #head-container {
    padding-top: 100px;
    // padding-bottom: 70px;
}

#head-container {

    .left {
        position: relative;
        figure {
            @extend .clearfix;

            img {
                width: 55%;
                box-shadow: 0px 0px 48px 0px rgba(0,0,0,0.3)
            }

            &.one {
                position: relative;
                img {
                    margin-left: 50px;
                    z-index: 1;
                }
            }
            &.two {
                img {
                    position: relative;
                    margin-top: -20%;
                    float: right;
                    z-index: 2;
                }
            }
            &.three {
                position: relative;
                img {
                    position: relative;
                    top: -90px;
                    z-index: 3;
                }
            }
        }

        .caption {
            position: relative;
            top: -70px;
            font-family: 'Pangolin', cursive;
            color: $colorHighlight;
            font-size: 1.6rem;
            line-height: 2.0rem;
            // padding-left: 10px;
        }
    }

    .right {
        figure {
            margin-top: 24%;
        }
        p.h1 {
            color: $colorHighlight;
        }
    }
}

#container #main {
    .dark {
        background: url(../images/KJP-BG_dark.jpg);
        // background: #eeeeed;
    }

    figure.bg-box {
        margin-bottom: 40px;
    }

    .yellow {
        h1,.h1,h2,.h2,h3,.h3 {
            color: $colorSecondary;
        }
        a.button {
            background: $colorSecondary;
        }
        figure.bg-box {
            &:after {
                background: $colorSecondary;
            }
        }
        ul.styled {
            li {
                &:before {
                    background: $colorSecondary;
                }
            }
        }
    }

    .grey-highlight {
        h1,.h1,h2,.h2,h3,.h3 {
            color: $colorHighlight;
        }
        a.button {
            background: $colorHighlight;
        }
        figure.bg-box {
            &:after {
                background: $colorHighlight;
            }
        }
        ul.styled {
            li {
                &:before {
                    background: $colorHighlight;
                }
            }
        }
    }

    .blue {
        h1,.h1,h2,.h2,h3,.h3 {
            color: $colorPrimary;
        }
        a.button {
            background: $colorPrimary;
        }
        figure.bg-box {
            &:after {
                background: $colorPrimary;
            }
        }
        ul.styled {
            li {
                &:before {
                    background: $colorPrimary;
                }
            }
        }
    }

    figure.bg-box {
        position: relative;

        img {
            position: relative;
            width: 80%;
            z-index: 10;
        }

        &:after {
            content: '';
            position: absolute;
            width: 80%;
            height: 150px;
            bottom: -20px;
            right: 10%;
            border-radius: 20px;
            z-index: 0;
        }

        &.right {
            text-align: right;
            img {
                display: inline-block;
            }
            &:after {
                content: '';
                position: absolute;
                width: 80%;
                height: 150px;
                bottom: -20px;
                left: 10%;
                border-radius: 20px;
                z-index: 0;
            }
        }
    }

    .rakete, .service, .about, .download {
        position: relative;

        img {
            position: relative;
            z-index: 100;
        }
    }

    .rakete {
        img {
            left: -100%;
        }
    }

    .service {
        img {
            left: -70%;
        }
    }

    .about {
        img {
            right: -43%;
            bottom: -40px;
        }
    }

    .download {
        img {
            right: -45%;
            bottom: -20px;
        }
    }

    .halfed {
        figure.small {
            img {
                width: 50%;
                margin: 0 auto;
                display: block;
            }
            &:after {
                width: 50%;
            }
        }
    }

    .gallery {
        margin-top: 50px;
        figure {
            margin-bottom: 40px;
            a {
                border-radius: 20px;
                display: block;
                overflow: hidden;
                img {
                    border-radius: 20px;
                    transition: transform 0.3s ease-in-out;
                }

                &:hover {
                    img {
                        transform: scale(1.1);
                    }
                }
            }
        }
        figcaption {
            font-family: 'Pangolin', cursive;
            color: $colorPrimary;
            text-align: center;
            font-size: 1.2rem;
        }
    }

    #googleMap {
        height: 400px;
    }
}

.js {
    #container #main {
        .accordeon {
            h2 {
                cursor: pointer;
                position: relative;
                display: inline-block;

                &:after {
                    content: '';
                    background: url(../images/icons/pfeil.svg) no-repeat;
                    background-size: 20px;
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    right: -30px;
                    top: 5px;
                    transition: all 0.3s ease-in-out;
                }
            }
            p {
                display: none;
            }

            &.open {
                h2:after {
                    transform: rotate(180deg);
                    top: -5px;
                }
            }
        }
    }
}

.mod_form {
    input, textarea {
        padding: 13px 20px;
        margin-bottom: 10px;
        border-radius: 20px;

        &.error {
            border: 1px solid #ff0000 !important;
        }
    }

    p.error {
        color: #ff0000;
    }

    span.error {
        color: #ff0000;
        margin-top: -10px;
        margin-bottom: 10px;
        font-size: 14px;
        padding-left: 10px;
    }

    .checkbox {
        span.error {
            margin-top: 0;
        }
    }

    .grecaptcha-badge {
        float: left;
        margin-bottom: 10px;
    }

    .submit {
        button {
            margin-bottom: 10px;
            margin-left: 10px;
            float: right;
            display: inline-block;
            padding: 10px 40px;
            border-radius: 30px;
            color: #fff;
            font-family: 'Roboto', sans-serif;
            background: $colorPrimary;
        }
    }
}

footer {
    position: relative;
    background: url(../images/KJP-BG_footer.jpg);
    padding-top: 50px;
    padding-bottom: 30px;
    color: #fff;

    table {
        width: 100%;
        max-width: 325px;
    }

    .footer_nav {
        /*        position: relative;
                top: 146px;*/

        /*        nav {
                    ul {
                        float: right;
                        text-align: right;
                    }
                }*/

        /*        &.small {
                    top: auto; 
                }*/
    }
    @media(max-width: 768px) {    
        figure {
            display: none
        }
    }
    @media(min-width: 769px) {        
        figure {
            img {
                position: absolute;
                right: $marginRight * 1px;
                top: $marginRight *3px;
            }
        }
    }
    @media(min-width: 1170px) {    
        figure {
            img {
                position: static;
            }
        }
        nav {
            ul {
                float: right;
                text-align: right;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    body {
        #container #main {
            .rakete {
                img {
                    width: 230px;
                }
            }
            .service, .about, .download {
                img {
                    width: 180px;
                }
            }
        }
        footer {
            .footer_nav {
                /*position: absolute;*/
                /*bottom: auto;*/
                /*top: 120px;*/
                /*right: 15px;*/

                /*                figure {
                                    img {
                                        float: right;
                                    }
                                }*/
            }
        }
    }
}

@media screen and (max-width: 980px) {
    body {
        #container #main {
            .rakete, .service, .about, .download {
                img {
                    position: absolute;
                    width: 200px;
                }
            }

            .rakete {
                img {
                    right: 0;
                    left: auto;
                    top: -100px;
                }
            }

            .service {
                img {
                    left: auto;
                    right: 0;
                    top: -200px;
                }
            }

            .about {
                img {
                    left: 0;
                    right: auto;
                    top: 160px;
                    bottom: auto;
                }
            }

            .download {
                img {
                    right: auto;
                    left: 0;
                    bottom: auto;
                    top: 160px;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    body {
        .mod_article, .padding {
            padding-top: 50px;
            padding-bottom: 50px;
        }
        header {
            nav#root {
                ul {
                    &:before {
                        display: none;
                    }
                }
            }
        }

        &.frontpage {
            #head-container {
                .left .imagemap {
                    display: none;
                }
                h1 {
                    // color: $colorPrimary !important;
                    text-align: center;
                }
                .right figure {
                    margin-top: 0;
                    margin-bottom: 0;
                    padding-bottom: 70px;
                }
            }
        }

        #container #main {
            .rakete, .service, .about, .download {
                img {
                    width: 120px;
                }
            }
            .about img {
                left: 0;
                right: auto;
                top: auto;
                bottom: -260px;
            }

            .service img {
                top: -100px;
            }
            .download img {
                right: auto;
                left: 0;
                top: auto;
                bottom: -250px;
            }
            .halfed {
                .col-sm-6.text-center {
                    text-align: left !important;
                }
                figure {
                    text-align: left !important;
                }
            }
        }

        footer {
            .footer_nav {
                /*                position: relative;
                                top: auto;
                                bottom: auto;
                                left: 0;
                                figure {
                                    img {
                                        float: none;
                                    }
                                }*/
            }
        }
    }
}

@media screen and (max-width: 440px) {
    body {
        #toplink {
            right: 10px;
            bottom: 10px;
        }
        #head-container .right p.h1 {
            font-size: 1.6rem;
        }
        footer {
            .mod_form {
                .grecaptcha-badge {
                    float: none;
                }
                .submit {
                    button {
                        float: left;
                        margin-left: 0;
                    }
                }
            }
        }
    }
}

// @import "_mobile_slide-in-menu";
@import "_mobile_menu";

// some bootstrap-like classes for easier frontend styling
@import "_bootstraplike";

/*@import "js/_easy-accordion";*/
/*@import "js/_easy-coverImage";*/

// some fakes to use some classes like text-align, bold, uppercase and so on
@include color-generator('primary', $colorPrimary);
@include color-generator('secondary', $colorSecondary);
@include color-generator('highlight', $colorHighlight);

// handling some browser-capabilities e.g. svg and javascript
@import "_modernizr.scss";

/*
    Created on : 17.04.2015, 09:34:00
    Descroption: Custom Contao 3.x Grid with fixed with
                 http://css-tricks.com/snippets/css/media-queries-for-standard-devices/
                 Default Breaks...
                 @768 for portrait > sm
                 @1024 for landscape > md
                 @1280 for desktops > lg
                 Percenatgegrid uses bootstrap-like classes col-xs-*, col-sm-*...

                 Add some more breakoint by extent $arrBreakpoints
                 08.07.2016: Fixed clear-floating
                 23.09.2016: tuned grid, fixed col-xx-hide
*/

@import "mixins/_generateGrid.scss";

$arrBreakpoints: (
(480 "xsm"),
(768 "sm"),
(981 "smd"),
(1024 "md"),
(1170 "lg"),
(1280 "xl")
);

$marginLeft: 15;
$marginRight: 15;
$intColumns: 12;

/* STOP edit here! */
/*$computedMarginLeft: percentage($marginLeft / $maxPageWidth);*/
/*$computedMarginRight: percentage($marginRight / $maxPageWidth);*/
$computedMarginLeft: $marginLeft + px;
$computedMarginRight: $marginRight + px;

.grid {
    min-width: 320px;
    margin: {
        left: auto;
        right: auto;
    }
    &-fluid {
        max-width: 100% !important;
    }
}

*[class*="col-"] {
    /*display: inline;*/
    box-sizing: border-box;
    float: left;
    margin: {
        left: $computedMarginLeft;
        right: $computedMarginRight;
    }
    &[class*="push-"],
    &[class*="pull-"] {
        position: relative;
    }
}

.mod_article[class*="col-"] {
    margin: {
        left: 0;
        right: 0;
    }
    /*padding: {
            left: $computedMarginLeft;
            right: $computedMarginRight;
        }*/
}

.row {
    clear: left;
    margin: {
        left: -$computedMarginLeft;
        right: -$computedMarginRight;
    }
}

// mobile devices, phones
@include generatePercentageGrid($intColumns, 'xs-', $marginLeft, $marginRight);

@media only screen and (max-width: nth(nth($arrBreakpoints, 1),1) - 1 + px) {
    .col-xs-#{$intColumns} {
        clear: left;
    }
}

@each $breakpoint in $arrBreakpoints {
    $intWidth: nth($breakpoint, 1);
    $strSort: nth($breakpoint, 2);
    $intIndex: index($arrBreakpoints, $breakpoint);
    @media only screen and (min-width: $intWidth + px) {
        @include generatePercentageGrid($intColumns, $strSort + '-', $marginLeft, $marginRight);
        .grid {
            max-width: $intWidth + px;
        }
    }
    @if $intIndex < length($arrBreakpoints) {
        $nextWidth: nth(nth($arrBreakpoints,$intIndex), 1);
        @media only screen and (min-width: $intWidth + px) and (max-width: $nextWidth - 1 + px) {
            .col-#{$strSort}-#{$intColumns} {
                clear: left;
            }
            .col-#{$strSort}-hide {
                display: none
            }
        }
    } @else {
        @media only screen and (min-width: $intWidth + px) {
            .col-#{$strSort}-#{$intColumns} {
                clear: left;
            }
            .col-#{$strSort}-hide {
                display: none
            }
        }
    }
}
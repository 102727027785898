/*
    Created on : 08.07.2016, 11:19:41
    Description: Neues Standardmenü für die mobile Ansicht
*/

label[for="nav-trigger"] {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 1002;
    span {
        $size: 20;
        $height: 3;
        float: left;
        &,
        &::before,
        &::after {
            background-color: $colorPrimary;
            content: "";
            display: block;
            position: relative;
            width: 30px;
            height: $height + px;
            top: 4px;
            transition: all 0.5s ease-in-out;
            opacity: 1;
        }
        &::before {
            top: 8px;
        }
        &::after {
            top: 13px;
        }
    }

    &.active {
        span {
            transform: translateY(5px) translateX(0) rotate(45deg);
            &, &::before, &::after {
                width: 22px;
            }
            &:before {
                opacity: 0;
            }
            &:after {
                transform: translateY(-12px) translateX(0) rotate(-90deg);
                top: 9px;
            }
        }
    }
}

#root {
    ul {

    }
    li {

    }
    a, span {

    }
}

@media screen and (max-width: 768px) {
    $intMaxMenuWidth: 220px;
    body, #wrapper {
        width: 100%;
        overflow-x: hidden;
    }
    header {
        nav#root {
            padding-top: 46px;
            transition: transform 220ms ease-in;
            transform: translate3d(#{$intMaxMenuWidth}, 0 ,0);
            position: absolute;
            max-width: $intMaxMenuWidth;
            z-index: 1;
            margin: 0;
            right: 0;
            top: 0;
            ul {
                float: none;
                li {
                    display: block;
                    a, span {
                        background: $colorPrimary;
                        border-bottom: 1px solid darken($colorPrimary,10%);;
                        padding: 7px 12px;
                        transition: background-color 1200ms;
                        display: block;
                        width: 100%;
                        border-radius: 10px;
                        margin: 5px 0;
                        &:hover {
                            transition-duration: 420ms;
                            background: darken($colorPrimary,10%);
                        }
                    }
                }
                .level_2 {
                    a, span {
                        background: $colorSecondary;
                        position: relative;
                        padding-left: 2 * 15 + px;
                    }
                }
            }
        }
    }
    #nav-trigger:checked {
        ~ #wrapper #root {
            transform: translate3d(0, 0, 0);
            transition-timing-function: easy-out;
            transition-duration: 420ms;
        }
        ~ #wrapper label[for="nav-trigger"] {
            span {
                transform: translateY(5px) translateX(0) rotate(45deg);
                &, &::before, &::after {
                    width: 22px;
                }
                &:before {
                    opacity: 0;
                }
                &:after {
                    transform: translateY(-12px) translateX(0) rotate(-90deg);
                    top: 9px;
                }
            }
        }
    }
}

@media screen and (min-width: 769px) {
    label[for="nav-trigger"] {
        display: none
    }
}